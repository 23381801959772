/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getData = /* GraphQL */ `
  query GetData($id: ID!, $rowType: String!, $username: ID!) {
    getData(id: $id, rowType: $rowType, username: $username) {
      id
      rowType
      username
      name
      familyName
      email
      image
      formData
      formVersion
      isAdmin
      isProvider
      providers
      patients
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listData = /* GraphQL */ `
  query ListData(
    $id: ID
    $rowTypeUsername: ModelDataPrimaryCompositeKeyConditionInput
    $filter: ModelDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listData(
      id: $id
      rowTypeUsername: $rowTypeUsername
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        rowType
        username
        name
        familyName
        email
        image
        formData
        formVersion
        isAdmin
        isProvider
        providers
        patients
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
