import React from "react";
import { View, Text, Authenticator, useTheme, Image, Heading } from '@aws-amplify/ui-react';


import RouteToCorrectPage from "./RouteToCorrectPage"

/**
 * This File Contains:
 *      - login landing page
 *      - custom text, image and placeholder text on login page
 *      - a RouteToCorrectPage where signout button and current user are passed in
 */

const App = () => {
  
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Helen Foundation logo"
            src="/helenFoundationLogo.png"
          />
          <Heading level={3}>Welcome to the Diary App</Heading>
          <br />
          <Text>Sign in or create an account to fill out your diary entries and track your progress.</Text>
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; 2022 The Helen Foundation
          </Text>
        </View>
      );
    },

  };

  const formFields = {
      signUp: {
        name: {
          placeholder: 'First Name',
          isRequired: true,
        },
        family_name: {
          placeholder: 'Last Name',
          isRequired: true,
        },
      },
      confirmResetPassword: {
        confirmation_code: {
          labelHidden: false,
          placeholder: 'Confirmation Code',
          label: 'Confirmation Code sent to provided email',
        },
      },
    };

  

  return (
    <Authenticator formFields={formFields} components={components}>
      {({ signOut, user }) => (<RouteToCorrectPage signOut={signOut} user={user}/>)} 
    </Authenticator>
  );
};

export default App;