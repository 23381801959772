import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from 'aws-amplify';
import {
  Text,
  Heading,
  View,
  SelectField,
} from '@aws-amplify/ui-react';
import { listData } from "./graphql/queries";
import PainScaleLineGraph from './PainScaleLineGraph'

/**
 * This File Contains:
 *      - logic to get non-current user, non-admin, non-provider users
 *      - a drop down menu populated by these users
 *      - PainScaleLineGraph filled by username of selected user from drop down
 */

const Dashboard = () => {
  const [dropDownUsers, setDropDownUsers] = useState([]);
  const [selectedDropDownUsername, setSelectedDropDownUsername] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    /**
     * This function gets users to populate drop down.  It queries rows with rowType = "user"
     * and not having the same username as the current user (all users will have a row with
     * rowType = "user"). Additionally, it creates an array of arrays with ordered, unique name, 
     * familyName, email, and usernames.
     * 
     * NOTE: This function does queries users that are not themselves and also not admins. This
     * is so that only patients will show up in the drop down menu. The first time an admin logs
     * in, their 'isAdmin' column in DynamoDB will not be set to true, so in order to not have
     * them show up in the drop down menu, I will only query users who are not themselves. Maybe
     * at some point it could be worthwhile making a better solution for this with like lambda 
     * functions or something.
     */

    let nextToken, apiData;
    let usersFromAPI = [];
    let uniqueUsers = new Set();

    // go until next token is null.  It starts as undefined.
    while (nextToken !== null ) {
      apiData = await API.graphql({
          query: listData,
          variables: { 
            nextToken: nextToken,
            filter: {
              rowType: {eq: "user"},
              username: {ne: Auth.user.username}, 
              isAdmin: {ne: true},
              isProvider: {ne: true},
            }
          }
      });
      nextToken = apiData.data.listData.nextToken
      usersFromAPI = [...usersFromAPI, ...apiData.data.listData.items];
    };

    await Promise.all(
      usersFromAPI.map(async user => {
        if (!!user.username) {
          // put user attributes in uniqueUsers as json string as ordered list, so duplicates will be removed and can be sorted
          uniqueUsers.add(JSON.stringify([
            user.name,
            user.familyName,
            user.email,
            user.username,
          ]))
        }
      })
    )

    // order uniqueUsers by name, familyName, then email and extract username from JSON
    uniqueUsers = [...uniqueUsers].sort().map((user) => {return JSON.parse(user)});
    setDropDownUsers(uniqueUsers);
  }

  return (
    <View className="Dashboard" padding="3rem 0" backgroundColor="Azure">
      <Heading level={1}>Provider Dashboard</Heading>
      <View margin="1rem 10%"  className="White-rounded-corners">
        <View className="White-rounded-corners" margin="1rem 20%" backgroundColor="WhiteSmoke">
          <Text fontWeight="600" fontSize='large'>Patient Selector</Text>
          <SelectField 
              backgroundColor='white'
              labelHidden
              placeholder="Choose a patient to see their data"
              value={selectedDropDownUsername}
              onChange={(e) => setSelectedDropDownUsername(e.target.value)}
          >
            {dropDownUsers.map((user, index) => 
                <option value={user[3]} key={index}>{user[0]} {user[1]} ({user[2]})</option>)}
          </SelectField>
        </View>
        <View>
          <PainScaleLineGraph username={selectedDropDownUsername} style={{minHeight: '600px'}}/>
        </View>
      </View>
    </View>
  );
};

export default Dashboard;
