import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API} from 'aws-amplify';
import {
  View,
} from '@aws-amplify/ui-react';
import { listData } from "./graphql/queries";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  /**
   * This File Contains:
   *      - logic to query from data for a username provided in props
   *      - logic to transorm and format form data (very specific to the 'helen_foundation_pain_scale_v1')
   *      - logic to title and format graph
   */

const PainScaleLineGraph = (props) => {  
  const [forms, setForms] = useState([]);
  const yAxis1Label = 'Pain Scale Sum';
  const yAxis2Label = 'Cortisol Taken, mg';
  const xAxisLabels = forms.map(form => {return form.createdAtReadable});
  const yAxis1Data = forms.map(form => {return form.painScaleSum});
  const yAxis2Data = forms.map(form => {return form.cortisolTakenDose});

  const GraphTitle = `${yAxis1Label} and ${yAxis2Label} Over Time`;

  useEffect(() => {
      fetchPainScales(props.username);
  }, [props]);


  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: GraphTitle,
        font: {
            size: 20
        }
      },
    },
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
            display: true,
            text: yAxis1Label,
        },
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
            display: true,
            text: yAxis2Label,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };
  
  const data = {
    labels: xAxisLabels,
    datasets: [
      {
        label: yAxis1Label,
        data: yAxis1Data,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
      },
      {
        label: yAxis2Label,
        data: yAxis2Data,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y2',
      },
    ],
  };


  async function fetchPainScales(username) {
    // only execute fetchPainScales if username is not null
    if (!username) {
        setForms([])
        return
    }

    let nextToken, apiData;
    let itemsFromAPICalls = [];

    // go until next token is null.  It starts as undefined.
    while (nextToken !== null ) {
      apiData = await API.graphql({
        query: listData,
        variables: { 
          nextToken: nextToken,
          filter: { 
            username: {eq: username}, 
            rowType: {eq: "form"}
          }
        }
      });
      nextToken = apiData.data.listData.nextToken
      itemsFromAPICalls = [...itemsFromAPICalls, ...apiData.data.listData.items];
    };
    const formsFromAPI = apiData.data.listData.items;
    
    // Format data to have `ints` for columns in `formData` and create `painScaleSum`, also create createdAtReadable
    await Promise.all(
      itemsFromAPICalls.map(async (form) => {
        if (form.formData) {
            let formDataRaw = JSON.parse(form.formData);
            let painScaleSum = 0;
            let cortisolTakenDose;
            for (const formElementName in formDataRaw){
                formDataRaw[formElementName] = parseInt(formDataRaw[formElementName])
                if (formElementName === 'cortisol_taken_mg') {
                    cortisolTakenDose = formDataRaw[formElementName]
                } else {
                    painScaleSum += formDataRaw[formElementName]
                }
            }
            form.painScaleSum = painScaleSum
            form.cortisolTakenDose = cortisolTakenDose
            form.formData = formDataRaw;
            form.createdAt = new Date(form.createdAt)
            form.createdAtReadable = form.createdAt.toLocaleTimeString(
                'en-US',
                    {
                    timeZone:'America/Chicago',
                    hour12:true,
                    month:'numeric',
                    day:'numeric',
                    year:'numeric',
                    hour:'numeric',
                    minute:'numeric',
                    }
                );
        }
        return form;
      })
    )

    // sort in ascending order by createdAt
    itemsFromAPICalls.sort((x, y) => {return x.createdAt - y.createdAt;})
    setForms(itemsFromAPICalls);
  }

  return (
    /**
     *   There are 2 views here so that the Chart.js graph can have space at the bottom, 
     * without these divs it just goes to the direct bottom of its containing div
     * */  

    <View className="PainScaleLineGraph" backgroundColor="white">
        <View padding="0 0 2.5% 0" style={props.style}> 
            <Line options={options} data={data}/>
        </View> 
    </View>
  );
};

export default PainScaleLineGraph;
