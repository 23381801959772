import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { API, Auth } from 'aws-amplify';
import {
  Button,
  Flex,
  Heading,
  Text,
  TextField,
  View,
} from '@aws-amplify/ui-react';
import {
  createData as createDataMutation,
} from "./graphql/mutations";
import SliderField0to10 from "./FormElements"
import PainScaleLineGraph from './PainScaleLineGraph'

/**
 * This File Contains:
 *      - form collecting user's cortisol levels and pain
 *      - logic to write the form to DynamoDB
 *      - a linegraph for current user to see their pain over vs cortisol over time
 */

const Form = () => {
  const [hasError, setHasError] = useState(false);
  const [currentUser, setCurrentUser] = useState(Auth.user);
  const [formSubmitted, setFormSubmitted] = useState(false);

  async function createData(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    const formData = {
      'cortisol_taken_mg': form.get('cortisol_taken_mg'),
      'brain_fog': form.get('brain_fog'),
      'depression': form.get('depression'),
      'fatigue': form.get('fatigue'),
      'headache' : form.get('headache' ),
      'stiffness' : form.get('stiffness' ),
      'abdomen': form.get('abdomen'),
      'chest': form.get('chest'),
      'upper_back': form.get('upper_back'),
      'lower_back': form.get('lower_back'),
      'neck': form.get('neck'),
      'left_foot': form.get('left_foot'),
      'right_foot' : form.get('right_foot' ),
      'left_ankle' : form.get('left_ankle' ),
      'right_ankle': form.get('right_ankle'),
      'left_knee': form.get('left_knee'),
      'right_knee': form.get('right_knee'),
      'left_hip': form.get('left_hip'),
      'right_hip': form.get('right_hip'),
      'left_leg_muscles': form.get('left_leg_muscles'),
      'right_leg_muscles': form.get('right_leg_muscles'),
      'left_hand': form.get('left_hand'),
      'right_hand': form.get('right_hand'),
      'left_wrist': form.get('left_wrist'),
      'right_wrist': form.get('right_wrist'),
      'left_elbow': form.get('left_elbow'),
      'right_elbow': form.get('right_elbow'),
      'left_shoulder': form.get('left_shoulder'),
      'right_shoulder': form.get('right_shoulder'),
      'left_arm_muscles': form.get('left_arm_muscles'),
      'right_arm_muscles': form.get('right_arm_muscles'),
    };
    const data = {
      rowType: 'form',
      username: currentUser.username,
      name: currentUser.attributes.name,
      familyName: currentUser.attributes.family_name,
      email: currentUser.attributes.email,
      formVersion: 'helen_foundation_pain_scale_v1',
      formData: JSON.stringify(formData),
    };
    await API.graphql({
      query: createDataMutation,
      variables: { input: data },
    });
    event.target.reset();
    setFormSubmitted(true);
  }
    
  const validateInputIsNumber = (e) => {
    const containsNonDigit = /\D/.test(e.currentTarget.value);
    setHasError(containsNonDigit);
  };

  const convertBooleanToDisplayCSS = (display) => {
    if (display) {
      return "block"
    } else {
      return "none"
    }

  }

  return (
    <View className="Form" backgroundColor='Azure'>
      <Heading level={1}>{currentUser.attributes.name}'s Diary Form</Heading>
      <View margin="5rem 10%" paddingBottom="3rem">
        <View id="formSubmittedNotice" className="Form-input-wrapper" display={convertBooleanToDisplayCSS(formSubmitted)}>
          <Text fontSize="1.2rem" color="grey">Diary Form Submitted</Text>
        </View>
        <View as="form" id="submissionForm" onSubmit={createData} display={convertBooleanToDisplayCSS(!formSubmitted)}>
          <Text fontSize="1.2rem">Welcome, {currentUser.attributes.name}.  Please fill out this form daily to monitor your progress.</Text>
          <br />
          <Flex direction="column" justifyContent="center">
            <View className="Form-input-wrapper">
              <TextField
                name='cortisol_taken_mg' 
                label={
                  <Text>
                    Cortisol Taken, mg
                    <Text as="span" fontSize="0.8rem" color="grey">
                      {' '}
                      (required)
                    </Text>
                  </Text>
                }
                hasError={hasError}
                errorMessage="Dosage must be a number"
                onChange={validateInputIsNumber}
                required
              />
            </View>
            <View className="Form-input-wrapper"><SliderField0to10 name='brain_fog' label='Brain Fog'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='depression' label='Depression'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='fatigue' label='Fatigue'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='headache' label='Headache'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='stiffness' label='Stiffness'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='abdomen' label='Abdomen'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='chest' label='Chest'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='upper_back' label='Upper Back'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='lower_back' label='Lower Back'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='neck' label='Neck'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_foot' label='Left Foot'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_foot' label='Right Foot'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_ankle' label='Left Ankle'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_ankle' label='Right Ankle'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_knee' label='Left Knee'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_knee' label='Right Knee'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_hip' label='Left Hip'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_hip' label='Right Hip'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_leg_muscles' label='Left Leg Muscles'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_leg_muscles' label='Right Leg Muscles'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_hand' label='Left Hand'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_hand' label='Right Hand'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_wrist' label='Left Wrist'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_wrist' label='Right Wrist'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_elbow' label='Left Elbow'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_elbow' label='Right Elbow'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_shoulder' label='Left Shoulder'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_shoulder' label='Right Shoulder'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='left_arm_muscles' label='Left Arm Muscles'/></View>
            <View className="Form-input-wrapper"><SliderField0to10 name='right_arm_muscles' label='Right Arm Muscles'/></View>
            <Button paddingTop='1.5rem' paddingBottom='1.5rem' type="submit" variation="primary">
              Submit
            </Button>
          </Flex>
        </View>
        
        <Heading marginTop='3rem' level={4}>{currentUser.attributes.name}'s Treatment Graph</Heading>

        <View className="Form-input-wrapper" marginTop='1rem'>
          <PainScaleLineGraph username={currentUser.username} style={{minHeight: '400px'}}/>
        </View>
      </View>
    </View>
  );
};

export default Form;