/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createData = /* GraphQL */ `
  mutation CreateData(
    $input: CreateDataInput!
    $condition: ModelDataConditionInput
  ) {
    createData(input: $input, condition: $condition) {
      id
      rowType
      username
      name
      familyName
      email
      image
      formData
      formVersion
      isAdmin
      isProvider
      providers
      patients
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateData = /* GraphQL */ `
  mutation UpdateData(
    $input: UpdateDataInput!
    $condition: ModelDataConditionInput
  ) {
    updateData(input: $input, condition: $condition) {
      id
      rowType
      username
      name
      familyName
      email
      image
      formData
      formVersion
      isAdmin
      isProvider
      providers
      patients
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteData = /* GraphQL */ `
  mutation DeleteData(
    $input: DeleteDataInput!
    $condition: ModelDataConditionInput
  ) {
    deleteData(input: $input, condition: $condition) {
      id
      rowType
      username
      name
      familyName
      email
      image
      formData
      formVersion
      isAdmin
      isProvider
      providers
      patients
      createdAt
      updatedAt
      owner
    }
  }
`;
