import React, { useState, useEffect } from "react";
import { View, Button, withAuthenticator, Text } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import { listData } from "./graphql/queries";
import { 
    createData as createDataMutation,
    updateData as updateDataMutation
} from "./graphql/mutations";

import Form from "./Form"
import Dashboard from "./Dashboard"
// import QueryAllData from "./QueryAllData"

/**
 * This File Contains:
 *      - logic to get show current user correct component based on whether or not they are a Provider/Admin
 *      - logic to add current user to DynamoDB with `rowType='user'` if their username does not have row yet
 *          - this is intended to happen on a user's first login.
 *          - user should not have Admin or Provider cognito groups on first login. For now user must be added manually in AWS
 *      - logic to update current user's DynamoDB `rowType='user'` row(s) to have isAdmin/isProvider reflect their cognito pools
 *          - this is because cognito only allows 100 users to be retrieved from API call, but graphql doesn't have limit
 *          - additionally, having isAdmin/isProvider = `true` will cause user to not show up in dropdown list in dashboard
 *              - there are plans to base this around a users' list of `providers` where admins can add users to providers
 */


const RouteToCorrectPage = ({ user, signOut }) => {
  const [cognitoGroups, setCognitoGroups] = useState(user.signInUserSession.accessToken.payload["cognito:groups"]);
  const [currentUserRows, setCurrentUserRows] = useState(getCurrentUserRows());

  useEffect(() => {
      addUserToDBIfNotThere();
      updateUserStatusesInDB()
    }, [currentUserRows]);

  const loadCorrectPage = () => {
    //  if admin or provider, load dashboard; if not, load form page
    if (cognitoGroups && (cognitoGroups.includes('Admin') || cognitoGroups.includes('Provider'))) {
      return <Dashboard />
    } else {
      return <Form/>
    }
  };

  async function getCurrentUserRows() {
    let nextToken, apiData;
    let itemsFromAPICalls = [];

    // go until next token is null.  It starts as undefined.
    while (nextToken !== null ) {
    apiData = await API.graphql({
      query: listData,
      variables: { 
        nextToken: nextToken,
        filter: {
          username: {eq: user.username}, 
          rowType: {eq: "user"}
        }
      }
    });
    nextToken = apiData.data.listData.nextToken
    itemsFromAPICalls = [...itemsFromAPICalls, ...apiData.data.listData.items];
    };
    return itemsFromAPICalls;
    }

  async function addUserToDBIfNotThere() {
    /**
     * This function looks up if the currently logged in user has a 'user' row in dynamoDB.
     * 
     * NOTE: There may be more than one 'user' row for a user in dynamoDB because I am not creating different 
     * graphQL 'type's and enforcing uniqueness in DynamoDB that way.  I don't understand how this works and 
     * I think it will take a lot of time that I don't have.  If I ever end up needing to scale this app up, 
     * I would like to go back and do this with smart NO-SQL data modeling, but I don't have the time now.
     */
    if (!currentUserRows) return
    let rows = currentUserRows
    rows.then(rows => {
        // console.log(rows)
      if (rows.length === 0) addCurrentUserToDB()
    });
  }

  async function setAdminStatusTrue(id) {
    await API.graphql({
        query: updateDataMutation,
        variables: { 
            input: { 
                id: id,
                rowType: 'user',
                username: user.username,
                isAdmin: true
                } 
            },
      });
    
  }

  async function setProviderStatusTrue(id) {
    await API.graphql({
        query: updateDataMutation,
        variables: { 
            input: { 
                id: id,
                rowType: 'user',
                username: user.username,
                isProvider: true
                } 
            },
      });
    
  }

  async function updateUserStatusesInDB() {
      /**
       * This function will update the 'isAdmin' and/or 'isProvider' column(s) to 
       * be true for users' rows in dynamoDB, if they are set to be an "Admin" or 
       * "Provider" via a cognito pool.  This is so that their name will not show 
       * up in a drop down list of users to look at pain scales for, and patients
       * can be assigned to providers.
       */
    if (!cognitoGroups) return 

    const currentUserIsAdmin = cognitoGroups.includes('Admin')
    const currentUserIsProvider = cognitoGroups.includes('Provider')
    if (!currentUserIsAdmin && !currentUserIsProvider) return

    let rows = currentUserRows
    rows.then(rows => {
        for (const row of rows) {
            if (currentUserIsAdmin && !row.isAdmin) setAdminStatusTrue(row.id)
            if (currentUserIsProvider && !row.isProvider) setProviderStatusTrue(row.id)
        }
    });
  }

  async function addCurrentUserToDB() {
    const data = {
      rowType: 'user',
      username: user.username,
      name: user.attributes.name,
      familyName: user.attributes.family_name,
      email: user.attributes.email,
      isAdmin: (!!cognitoGroups &&  cognitoGroups.includes('Admin')),
      isProvider: (!!cognitoGroups &&  cognitoGroups.includes('Provider')),
      providers: JSON.stringify([]), // eventually this may be filled in by some sort of referral link
      patients: JSON.stringify([]), // eventually this may be filled in an admin portal
    };

    await API.graphql({
      query: createDataMutation,
      variables: { input: data },
    });
  }

  return (
    <View className="App">
      {loadCorrectPage()}
      {/* <Form/> */}
      {/* <QueryAllData /> */}
      <Button margin="3rem 0" backgroundColor='SlateGray' onClick={signOut}>Sign Out</Button>
      <Text margin="1rem 0">&copy; 2022 The Helen Foundation</Text> 
    </View>
  );
};

export default RouteToCorrectPage;